import GlideController from '../glide_controller';
import Glide from '@glidejs/glide';

export default class extends GlideController {
  handleInitEvent(e){
    this.hideUnecessaryItemDescription(e);
    window.KTApp.unblock(this.element);

    if(this.element.classList.contains('lazyGlideInitialized')){
      return;
    }

    this.glide = new Glide(this.element, this.options);
    this.glide.mount();
    this.element.classList.add('lazyGlideInitialized');

  }

  hideUnecessaryItemDescription(e){
    const name = e.detail.name.split('(')[0].trim();

    Array.prototype.slice.call(
      this.element.querySelectorAll(`[my-custom-data]`)
    ).forEach((el) => {
      el.classList.add('d-none');
    });

    Array.prototype.slice.call(
      this.element.querySelectorAll(`[my-custom-data^="${name}"]`)
    ).forEach((el) => {
      el.classList.remove('d-none');
    });
  }
}
