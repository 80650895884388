import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path');
  }

  connect(){
    this.element.submit();
    // window.Ajax.post(this.path, this.ajaxOptions);
  }

  // handleSuccess(responseText){
  // }
  // handleFail(responseText){
  // }
  // handleDone(){
    // window.KTApp.unblockPage();
  // }

  // get ajaxOptions(){
    // return {
      // onFail: this.handleFail.bind(this),
      // onDone: this.handleDone.bind(this),
      // onSuccess: this.handleSuccess.bind(this),
      // data: JSON.stringify(this.payload),
      // headers: [
        // {
          // key: 'X-CSRF-Token',
          // value: window.Util.getCsrfToken()
        // },
        // {
          // key: 'Content-Type',
          // value: 'application/json'
        // },
      // ]
    // };
  // }

  // get payload(){
    // return {
      // cf: this.cf
    // }
  // }

  // get cf(){
    // let result = window.location.search;
    // result = result.split('?')[1];
    // result = result.split("&").filter((x) => {return x.match(/cf\=/)})[0];
    // result = result.split('=')[1];
    // return result;
  // }
}
