import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  add(e){
    const latestItem = this.latestItem
    const clonedNode = latestItem.cloneNode(true);

    // remove select .select2-hidden-accessible data-select2-id tabindex aria-hidden
    // remove span.select2
    const select2s = Array.prototype.slice.call(
      clonedNode.querySelectorAll('select[data-controller="select2"]')
    )
    select2s.forEach(x => {
      x.classList.remove('select2-hidden-accessible');
      x.removeAttribute('data-select2-id');
      x.removeAttribute('tabindex');
      x.removeAttribute('aria-hidden');
      x.id = window.Util.uuidv4();

      Array.prototype.slice.call(
        x.querySelectorAll('option')
      ).forEach(y => {
        y.removeAttribute('data-select2-id');
      });

      this.bindItemCanOnlyBeSelectedOnce(x);
    });
    Array.prototype.slice.call(
      clonedNode.querySelectorAll('span.select2')
    ).forEach(x => {
      window.Util.removeElement(x);
    })

    latestItem.insertAdjacentElement('afterend', clonedNode);
  }

  bindItemCanOnlyBeSelectedOnce(selectElement){
    if(selectElement.name !== 'career_aspiration[skills][][skill_id]'){
      return;
    }

    $(selectElement).on('select2:selecting', function(e){
      this.allSkillIdSelectElements.forEach(x => {
        if(e.params.args.data.id === x.value){
          e.preventDefault();
          return;
        }
      })
    }.bind(this));
  }

  remove(e){
    if(this.itemTargets.length <= 1){
      return;
    }

    const itemElement = this.getDirectParentRowItemElement(e.srcElement);
    window.Util.removeElement(itemElement);
  }

  get allSkillIdSelectElements(){
    return this.element.querySelectorAll('select[name="career_aspiration[skills][][skill_id]"]');
  }

  get latestItem(){
    return this.itemTargets[this.itemTargets.length-1];
  }

  getDirectParentRowItemElement(e){
    let result = e;
    while(!result.classList.contains('row') || !result.getAttribute('data-admin--careers--skill-dynamic-input-target')){
      result = result.parentElement;
    }

    return result;
  }
}
