import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns() {
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'treshold',
        title: 'Treshold',
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">
            ${data.bottom_treshold} - ${data.upper_treshold || ''}
          </span>`;
        }
      },
      {
        field: 'definition',
        title: 'Definisi',
        width: 200,
        sortable: false,
        autoHide: true,
        template: function (data) {
          return `<span class="font-weight-bolder">
            ${data.definition}
          </span>`;
        }
      },
      {
        field: 'terms',
        title: 'Prasyarat',
        width: 200,
        sortable: false,
        autoHide: true,
        template: function (data) {
          return `<span class="font-weight-bolder">
            ${data.terms}
          </span>`;
        }
      },
      {
        field: 'proof_of_work',
        title: 'Bukti Aktivitas Berkarir',
        width: 200,
        sortable: false,
        autoHide: true,
        template: function (data) {
          return `<span class="font-weight-bolder">
            ${data.proof_of_work}
          </span>`;
        }
      },
      {
        field: 'chance_and_benefit',
        title: 'Kesempatan & Benefit',
        width: 200,
        sortable: false,
        autoHide: true,
        template: function (data) {
          return `<span class="font-weight-bolder">
            ${data.chance_and_benefit}
          </span>`;
        }
      },
      // {
        // field: 'Actions',
        // title: 'Actions',
        // sortable: false,
        // width: 120,
        // overflow: 'visible',
        // autoHide: false,
        // template: function (data) {
          // return `
            // <a
              // class="btn btn-sm btn-clean btn-icon"
              // href="${data.show_path}"
              // title="Tampilkan"
            // >
              // <i class="fa fa-eye text-primary"></i>
            // </a>
          // `;
        // },
      // }
    ]
  }
}
