import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path');
  }

  handleClick(e){
    window.KTApp.blockPage();
    window.Ajax.post(this.path, this.ajaxOptions);
  }


  handleSuccess(responseText){
    const existingModalElement = document.querySelector('.js-modal.lcs-cikal-modal');
    if(existingModalElement){
      existingModalElement.innerHTML = responseText;
      existingModalElement.classList.remove('d-none');
      this.bindClosePropagate(existingModalElement);
      return;
    }

    let html = `<div class="js-modal lcs-cikal-modal">${responseText}</div>`;
    document.body.insertAdjacentHTML('afterbegin', html);
    this.bindClose(document.querySelector('.js-modal.lcs-cikal-modal'));
  }

  bindClose(modal){
    modal.addEventListener('click', (e) => {
      modal.classList.add('d-none');
    });
    this.bindClosePropagate(modal);
  }
  bindClosePropagate(modal){
    const content = modal.querySelector('form.modal-content');
    content.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  }

  handleFail(responseText){
    console.log('[ERROR]', responseText);
  }
  handleDone(){
    window.KTApp.unblockPage();
  }

  get ajaxOptions(){
    return {
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this)
    }
  }
}
