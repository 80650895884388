import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  initialize(){
    super.initialize();
    this.portofolioTabButton = document.querySelector('#portofolio-tab');
  }

  connect(){
    super.connect();
    this.portofolioTabButton.addEventListener('click', this.handleClick.bind(this));
  }
  handleClick(){
    this.datatable.reload();
  }

  datatableColumns() {
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'center',
        template: function (data) {
          return `<span class="">${data.index}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal',
        template: function (data) {
          return `<span class="">${data.created_at}</span>`;
        }
      },
      {
        field: 'career_name',
        title: 'Karier',
        sortable: false,
        template: function (data) {
          return `<span class="">${data.career_name}</span>`;
        }
      },
      {
        field: 'role_count',
        title: 'Jumlah Peran',
        sortable: false,
        template: function (data) {
          return `<span class="">${data.role_count}</span>`;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function (data) {
          return `
            <a
              class="btn btn-sm btn-clean btn-icon"
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#PortfolioDetail"
              data-controller="users--portfolios--show"
              data-users--portfolios--show-path="${data.show_path}"
              data-action="click->users--portfolios--show#handleClick"
            >
              <i class="la la-eye"></i>
            </a>
          `;
        },
      }
    ]
  }
}
