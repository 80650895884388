import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.dataId = $(this.element).data('id')
  }

  connect(){
    this.splide = new Splide(this.element, this.options);
    this.splide.mount();
  }

  get options(){
    if(this.data.get('opt') && this.data.get('opt') === '2'){
      return {
        perPage: 2,
        rewind : true,
        pagination: false,
        gap: 10,
        breakpoints: {
          425: {
            perPage: 1,
          },
          768: {
            perPage: 1,
          },
          1024:{
            perPage: 1,
          },
          // 1440:{
          // perPage: 4,
          // },
          // 2560: {
          // perPage: 5,
          // },
        }
      }
    }
    return {
      perPage: 3,
      rewind : true,
      pagination: false,
      gap: 10,
      breakpoints: {
        425: {
          perPage: 1,
        },
        768: {
          perPage: 2,
        },
        1024:{
          perPage: 2,
        },
        // 1440:{
        // perPage: 4,
        // },
        // 2560: {
        // perPage: 5,
        // },
      }
    }
  }
}
