import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['icon', 'input'];

  initialize(){
    this.checkedIconUrl = this.data.get('checkedIconUrl');
    this.uncheckedIconUrl = this.data.get('uncheckedIconUrl');
  }

  handleClick(e){
    this.toggleIcon();
    this.updateInput();
  }

  updateInput(){
    if(this.inputTarget.value === '0'){
      this.inputTarget.value = '1';
      return;
    }
    this.inputTarget.value = '0';
  }

  toggleIcon(){
    if(this.inputTarget.value === '0'){
      this.iconTarget.src = this.checkedIconUrl;
      this.iconTarget.style.boxShadow = "3px 3px 4px #004008";
      return;
    }

    this.iconTarget.src = this.uncheckedIconUrl;
    this.iconTarget.style.boxShadow = "";
  }

  handleSuccess(responseText){
  }

  handleFail(responseText){
    console.log('[ERROR]', responseText);
  }
  handleDone(){
    window.KTApp.unblockPage();
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this)
    }
  }
}
