import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  connect(){
    super.connect();
    this.element.addEventListener('users--careers--index:change', this.handleChangeEvent.bind(this));
  }
  handleChangeEvent(e){
    this.datatable.reload();
  }

  datatableColumns() {
    return [
      {
        field: 'index',
        title: '#',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        autoHide: false,
        overflow: 'visible',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal',
        width: 200,
        autoHide: false,
        overflow: 'visible',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.date}</span>`;
        }
      },
      {
        field: 'role',
        title: 'Peran',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.role}</span>`;
        }
      },
      {
        field: 'type_of_work',
        title: 'Jenis Karya/Layanan',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.type_of_work}</span>`;
        }
      },
      {
        field: 'scope',
        title: 'Lingkup',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.scope}</span>`;
        }
      },
      {
        field: 'topic',
        title: 'Topik',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.topic}</span>`;
        }
      },
      {
        field: 'total_credits',
        title: 'Kredit',
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.total_credits}</span>`;
        }
      },
      {
        field: 'evidence',
        title: 'Bukti',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder"></span>`;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function (data) {
          return `
            <a
              class="btn btn-sm btn-clean btn-icon"
              href="javascript:void(0);"
              title="Tampilkan"
              data-controller="users--routes"
              data-users--routes-id="careers/${data.id}/edit"
              data-action="click->users--routes#handleClick"
            >
              <i class="fa fa-eye text-dark"></i>
            </a>
          `;
        },
      }
    ]
  }
}
