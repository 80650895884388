import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    $(this.element).popover({
      html: true,
      content: function() {
        var content = $(this).attr("data-popover-content");
        return $(content).children(".popover-body").html();
      },
      title: function() {
        var title = $(this).attr("data-popover-content");
        return $(title).children(".popover-heading").html();
      }
    })
  }
}

