import { Controller } from 'stimulus';

export default class extends Controller {
  handleClick(e){
    if(this.data.get('query') === 'plan'){
      window.KTApp.blockPage();
      window.setTimeout(() => {
        const event = new Event('users--career-aspiration-plans--index:change');
        this.datatableElement.dispatchEvent(event);
        window.KTApp.unblockPage();
      }, 300);
    }

    this.updateUrl();
  }

  updateUrl(){
    window.history.pushState({},'',`${window.location.pathname}?tab=${this.data.get('query')}`);
  }

  get datatableElement(){
    return document.querySelector('div[data-controller="users--career-aspiration-plans--index"]');
  }
}
