import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path');
  }

  handleChange(e){
    window.KTApp.blockPage();

    let shown = "0";
    if(this.element.checked){
      shown = "1";
    }

    this.payload = {
      event: { shown: shown },
    };
    window.Ajax.post(this.path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    console.log('[SUCCESS]');
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }

  handleDone(){
    window.KTApp.unblockPage();
  }

  get ajaxOptions(){
    return {
      data: JSON.stringify(this.payload),
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
      ]
    };
  }
}
