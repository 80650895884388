import { Controller } from 'stimulus';

export default class extends Controller {
  handleClick(e){
    const event = new CustomEvent('glide:initializer', { detail: {
      name: this.getSrcElement(e.srcElement).textContent
    }});
    window.KTApp.block(this.glideElement);

    window.setTimeout(() => {
      this.glideElement.dispatchEvent(event);
    }, 1000);
  }

  get glideElement(){
    return document.querySelector('#GlideLazyFor5Cs');
  }

  getSrcElement(srcElement){
    let res = srcElement;
    while(!res.getAttribute('data-action') || res.getAttribute('data-action') != 'click->users--careers--show--lazy-glide-initializer#handleClick'){
      res = res.parentElement;
    }

    return res;
  }
}
