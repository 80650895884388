import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns() {
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'stage_id',
        title: 'Stage',
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">
            ${data.stage}
          </span>`;
        }
      },
      {
        field: 'career_id',
        title: 'Career',
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">
            ${data.career}
          </span>`;
        }
      },
      {
        field: 'description',
        title: 'Description',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">
            ${data.description}
          </span>`;
        }
      },
      // {
        // field: 'Actions',
        // title: 'Actions',
        // sortable: false,
        // width: 120,
        // overflow: 'visible',
        // autoHide: false,
        // template: function (data) {
          // return `
            // <a
              // class="btn btn-sm btn-clean btn-icon"
              // href="${data.show_path}"
              // title="Tampilkan"
            // >
              // <i class="fa fa-eye text-primary"></i>
            // </a>
          // `;
        // },
      // }
    ]
  }
}
