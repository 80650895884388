import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.initMessageBox();
  }

  initMessageBox(){
    tinymce.init({
        selector: ".js-input-message",
        menubar: false,
        statusbar: false,
        branding: false,
        content_css: 'writer',
        toolbar: ['styleselect fontselect fontsizeselect',
            'undo redo | cut copy paste | bold italic | alignleft aligncenter alignright alignjustify',
            'bullist numlist | outdent indent | blockquote subscript superscript | advlist  | lists charmap | print preview |  code'],
        plugins : 'advlist image lists charmap print preview code'
    });
  }
}
