import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns() {
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function (data) {
          return `<span class="">${data.index}</span>`;
        }
      },
      {
        field: 'career',
        title: 'Karier',
        sortable: false,
        template: function (data) {
          return `<span class="">${data.career}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        template: function (data) {
          return `<span class="">${data.name}</span>`;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function (data) {
          return `
            <a
              class="btn btn-sm btn-clean btn-icon"
              href="javascript:void(0);"
              title="Tampilkan"
              data-toggle="modal"
              data-target="#ShowClub"
              data-controller="admin--clubs--show"
              data-action="click->admin--clubs--show#handleClick"
              data-admin--clubs--show-path="${data.show_path}"
            >
              <i class="fa fa-pencil-alt"></i>
            </a>
            <a
              href="${data.show_path}"
              data-method="delete"
              data-confirm="Apakah anda yakin ingin menghapus data ini?"
              class="btn btn-sm btn-clean btn-icon btn-hover-light-danger"
              title="Delete"
            >
              <i class="la la-trash"></i>
            </a>
          `;
        },
      }
    ]
  }
}
