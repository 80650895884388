import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
  }

  submit(e){
    e.preventDefault();
    if(!this.evidenceElement.value.trim()){
      this.showAlert();
      return;
    }
    this.element.submit();
  }

  showAlert(){
    Swal.fire({
      icon: 'error',
      title: 'Isian belum lengkap.',
      text: 'Hasil karya / layanan wajib diisi',
      footer: '<a href="">Kamu belum mengisi hasil karya / layanan pada bagian Jenis Karya / Layanan</a>'
    })
  }

  get evidenceElement(){
    if(this._evidenceElement){
      return this._evidenceElement;
    }
    this._evidenceElement = this.element.querySelector('input[name="career_aspiration[evidence]"]');
    return this._evidenceElement;
  }
}
