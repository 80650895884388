import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns() {
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'profiles.ecn',
        title: 'ECN',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.profiles.ecn}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'email',
        title: 'Email',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.email}</span>`;
        }
      },
      {
        field: 'profiles.group',
        title: 'Type',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.profiles.group}</span>`;
        }
      },
      {
        field: 'profiles.employment_status',
        title: 'Employment Status',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.profiles.employment_status}</span>`;
        }
      },
      {
        field: 'profiles.spv_name',
        title: 'Nama SPV',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.profiles.spv_name}</span>`;
        }
      },
      {
        field: 'profiles.spv_email',
        title: 'Email SPV',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.profiles.spv_email}</span>`;
        }
      },
      {
        field: 'profiles.pda_balance_cents',
        title: 'Saldo PDA',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.profiles.pda_balance}</span>`;
        }
      },
      {
        field: 'profiles.orientation_90_days_status',
        title: '90 Days Status',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.profiles.orientation_90_days_status}</span>`;
        }
      },
      {
        field: 'roles',
        title: 'Role',
        sortable: false,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.roles}</span>`;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function (data) {
          return `
            <a
              class="btn btn-sm btn-clean btn-icon"
              href="${data.show_path}"
              title="Tampilkan"
            >
              <i class="fa fa-eye text-primary"></i>
            </a>
          `;
        },
      }
    ]
  }
}
