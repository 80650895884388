import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  connect(){
    super.connect();
    this.element.addEventListener('users--orientation90-day-staffs--index:change', this.handleChangeEvent.bind(this));
  }
  handleChangeEvent(e){
    this.datatable.reload();
  }

  datatableColumns() {
    return [
      {
        field: 'index',
        title: '#',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        autoHide: false,
        overflow: 'visible',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'period',
        title: 'Periode',
        width: 200,
        autoHide: false,
        overflow: 'visible',
        template: function (data) {
          return `
            <div>
              <a href="#" class="text-dark-75 font-weight-bolder mb-1">
                ${data.period}
              </a>
              <span
                style="font-size:10px;font-weight:bold;padding:3px 4px;"
                class="btn btn-sm btn-${data.status_class_html}"
              >
                ${data.status}
              </span>
            </div>
          `;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.date}</span>`;
        }
      },
      {
        field: 'user',
        title: 'User',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.user}</span>`;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function (data) {
          let actions = '';
          if(data.status_raw !== 'finished'){
            actions = `
              <a
                href="${data.show_path}"
                class="btn btn-sm btn-clean btn-icon btn-hover-light-primary"
                title="Lihat"
                data-controller="tooltip"
              >
                <i class="la la-eye text-dark text-hover-primary"></i>
              </a>
            `;
          }

          return `
            ${actions}
          `;
        },
      }
    ]
  }
}
