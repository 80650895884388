import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path');
  }
  connect(){
    $(this.element).on('select2:select', this.handleChange.bind(this))
  }

  handleChange(){
    const path = `${this.path}?career_id=${this.element.value}&${this.topicId}`;
    window.KTApp.blockPage();
    window.Ajax.post(path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    this.topicItemContainer.innerHTML = responseText;
  }
  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }
  handleDone(){
    window.KTApp.unblockPage();
  }

  get topicItemContainer(){
    return document.querySelector('.js-topic-item-container');
  }

  get topicId(){
    const activedRole = document.querySelector('.js-topic-item.active');
    if(!activedRole || !activedRole.dataset.topicId){
      return '';
    }
    return `topic_id=${activedRole.dataset.topicId}`;
  }

  get ajaxOptions(){
    return {
      onDone: this.handleDone.bind(this),
      onFail: this.handleFail.bind(this),
      onSuccess: this.handleSuccess.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
      ]
    };
  }
}
