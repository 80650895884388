import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns() {
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function (data) {
          return `<span class="">${data.index}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal',
        template: function (data) {
          return `<span class="">${data.created_at}</span>`;
        }
      },
      {
        field: 'user',
        title: 'User',
        sortable: false,
        template: function (data) {
          return `<span class="">${data.user}</span>`;
        }
      },
      {
        field: 'group',
        title: 'Grup',
        sortable: false,
        template: function (data) {
          return `<span class="">${data.group}</span>`;
        }
      },
    ]
  }
}
