import { Controller } from 'stimulus';

export default class extends Controller {
  handleClick(e){
    const skillInputElements = this.skillInputElements;

    skillInputElements.forEach(x => {
      if(this.element.checked){
        x.previousElementSibling.classList.remove('text-muted');
        x.removeAttribute('disabled');
        x.checked = true;
      } else {
        x.previousElementSibling.classList.add('text-muted');
        x.setAttribute('disabled','disabled');
        x.checked = false;
      }
    });

    if(this.element.checked){
      this.careerComponentRoleInputElement.removeAttribute('disabled');
    } else {
      this.careerComponentRoleInputElement.setAttribute('disabled', 'disabled');
    }
  }

  get careerComponentRoleInputElement(){
    if(this._careerComponentRoleInputElement){
      return this._careerComponentRoleInputElement;
    }

    this._careerComponentRoleInputElement = this.portfolioContainer.querySelector('input[type="hidden"][name="portfolio[entries][][career_component_role_id]"]');
    return this._careerComponentRoleInputElement;
  }

  get portfolioContainer(){
    let result = this.element;
    while(!result.classList.contains('js-portfolio-inputs-container')){
      result = result.parentElement;
    }
    return result;
  }

  get skillInputElements(){
    return Array.prototype.slice.call(
      this.container.querySelectorAll('input[type="checkbox"][name="portfolio[entries][][career_aspiration_entries][][skill_entries][][skill_id]"]')
    );
  }

  get container(){
    let result = this.element;
    while(!result.classList.contains('js-portfolio-inputs-career-aspiration-container')){
      result = result.parentElement;
    }
    return result;
  }
}
