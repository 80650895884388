import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    $(this.element).datepicker(this.options);
  }

  get options(){
    const result = {
      todayHighlight: true,
      autoclose: true,
      format: 'dd/mm/yyyy'
    }

    const minDate = this.data.get('minDate');
    if(!minDate){
      return result;
    }

    if(minDate === 'present-future'){
      result['startDate'] = this.today;
    }
    return result;
  }

  get today(){
    return new Date();
  }
}
