import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.imageInput = new KTImageInput(this.element);
  }

  connect(){
    this.imageInput.on('cancel', function(imageInput) {
      swal.fire({
        title: 'Image successfully changed !',
        type: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok!',
        confirmButtonClass: 'btn btn-primary font-weight-bold'
      });
    });

    this.imageInput.on('change', function(imageInput) {
      swal.fire({
        title: 'Image successfully changed !',
        type: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok!',
        confirmButtonClass: 'btn btn-primary font-weight-bold'
      });
    });

    this.imageInput.on('remove', function(imageInput) {
      swal.fire({
        title: 'Image successfully removed !',
        type: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Got it!',
        confirmButtonClass: 'btn btn-primary font-weight-bold'
      });
    });
  }
}

