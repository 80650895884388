import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.bindedHandleClick = this.handleClick.bind(this);
  }

  connect(){
    this.links.forEach((x) => {
      x.addEventListener('click', this.bindedHandleClick);
    });
    this.forbiddenLinks.forEach((x) => {
      x.removeEventListener('click', this.bindedHandleClick);
      x.setAttribute('target', '_blank');
    });
  }

  handleClick(e){
    this.loader.classList.add('d-block');
  }

  get loader(){
    if(this._loader){
      return this._loader;
    }

    this._loader = document.querySelector('.my-loader-container');
    return this._loader;
  }

  get links(){
    return Array.prototype.slice.call(
      document.querySelectorAll(
        'a:not([href="#"]):not([href*="javascript:void(0)"]):not([data-toggle="tab"]):not([data-loader="false"])'
      )
    );
  }

  get forbiddenLinks(){
    return Array.prototype.slice.call(
      document.querySelectorAll(
        '.trix-content action-text-attachment a'
      )
    );
  }
}
