import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  connect(){
    super.connect();
    this.element.addEventListener('users--career-aspiration-plans--index:change', this.handleChangeEvent.bind(this));
  }
  handleChangeEvent(e){
    this.datatable.reload();
  }

  datatableColumns() {
    return [
      {
        field: 'index',
        title: '#',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        autoHide: false,
        overflow: 'visible',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'started_at',
        title: 'Tanggal Mulai',
        width: 200,
        autoHide: false,
        overflow: 'visible',
        template: function (data) {
          return `
            <div>
              <span href="#" class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                ${data.started_at}
              </span>
              <span style="font-size:11px;font-weight:bold;padding:3px 4px;" class="text-white badge badge-sm badge-${data.status_class_html}">
                ${data.status}
              </span>
            </div>
          `;
        }
      },
      {
        field: 'role',
        title: 'Peran',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.role}</span>`;
        }
      },
      {
        field: 'type_of_work',
        title: 'Jenis Karya/Layanan',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.type_of_work}</span>`;
        }
      },
      {
        field: 'scope',
        title: 'Lingkup',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.scope}</span>`;
        }
      },
      {
        field: 'topic',
        title: 'Topik',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.topic}</span>`;
        }
      },
      {
        field: 'ended_at',
        title: 'Tanggal Selesai',
        sortable: false,
        width: 200,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.ended_at}</span>`;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function (data) {
          let actions = '';
          if(data.status_raw !== 'finished'){
            actions = `
              <a
                class="btn btn-sm btn-clean btn-icon btn-hover-light-warning"
                href="${data.convert_to_real_path}"
                title="Lengkapi hasil karya kegiatan dari rencana ini"
                data-controller="tooltip"
              >
                <i class="fas fa-cogs text-dark text-hover-warning"></i>
              </a>
              <a
                href="${data.show_path}"
                data-method="delete"
                data-confirm="Apakah anda yakin ingin menghapus data ini?"
                class="btn btn-sm btn-clean btn-icon btn-hover-light-danger"
                title="Hapus data ini"
                data-controller="tooltip"
              >
                <i class="la la-trash text-dark text-hover-danger"></i>
              </a>
            `;
          }

          return `
            ${actions}
          `;
        },
      }
    ]
  }
}
