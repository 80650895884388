import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.source = document.querySelector(this.data.get('sourceSelector'));
    this.path = this.data.get('path');
  }

  connect(){
    $(this.source).on('select2:select', this.handleChange.bind(this));
  }

  handleChange(e){
    window.KTApp.blockPage();
    const path = this.path.replace(/\-1/, this.source.value);
    window.Ajax.post(path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    this.element.innerHTML = responseText;
  }
  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }
  handleDone(){
    window.KTApp.unblockPage();
  }

  get ajaxOptions(){
    return {
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      onSuccess: this.handleSuccess.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
      ]
    };
  }
}
