import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path');
  }

  handleClick(e){
    const path = `${this.path}?${this.topicId}&${this.careerId}`;
    window.KTApp.blockPage();
    window.Ajax.post(path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    this.topicItemContainer.innerHTML = responseText;
    const actived = document.querySelector('.js-topic-selection .js-topic-item.nav-link.active');
    actived.classList.remove('active');

    this.element.classList.add('active');
  }
  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }
  handleDone(){
    window.KTApp.unblockPage();
  }

  get topicItemContainer(){
    return document.querySelector('.js-topic-item-container');
  }

  get topicId(){
    const topicId = this.element.dataset.topicId;
    if(!topicId){
      return '';
    }
    return `topic_id=${topicId}`;
  }

  get careerId(){
    const careerId = document.querySelector('select[name="career_id"]');
    if(!careerId.value){
      return '';
    }
    return `career_id=${careerId.value}`;
  }

  get ajaxOptions(){
    return {
      onDone: this.handleDone.bind(this),
      onFail: this.handleFail.bind(this),
      onSuccess: this.handleSuccess.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
      ]
    };
  }
}
