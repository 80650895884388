import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.initDatePicker();
  }

  initDatePicker(){
    $(this.element).daterangepicker(
      this.daterangepickerOptions, this.cb.bind(this)
    );
    this.cb(this.start, this.end, '');
  }

  get daterangepickerOptions(){
    const opts = {
      direction: KTUtil.isRTL(),
      startDate: this.start,
      endDate: this.end,
      opens: 'left',
      applyClass: 'btn-primary',
      cancelClass: 'btn-light-primary',
    };

    if(this.data.get('singlePick') && this.data.get('singlePick')==='1'){
      opts['singleDatePicker'] = true;
    } else {
      opts['ranges'] = {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      };
    }

    if(this.data.get('restriction') && this.data.get('restriction')==='currentMonth'){
      opts['minDate'] = moment(this.data.get('startDate'), "DD/MM/YYYY").startOf('month');
      opts['maxDate'] = moment(this.data.get('startDate'), "DD/MM/YYYY").endOf('month');
    }
    return opts;
  }

  cb(start, end, label) {
    let title = '';
    let range = '';

    if(this.data.get('singlePick') && this.data.get('singlePick')==='1'){
      range = start.format('MMM D');
    } else {
      range = start.format('MMM D') + ' - ' + end.format('MMM D');
    }

    $(this.element.querySelector('#kt_dashboard_daterangepicker_date_custom'))
      .html(range)
    $(this.element.querySelector('#kt_dashboard_daterangepicker_title_custom'))
      .html(title)
  }

  get start(){
    let start = this.data.get('startDate');
    if(start){
      return moment(this.data.get('startDate'), "DD/MM/YYYY");
    }
    return moment().startOf('year');
  }

  get end(){
    let end = this.data.get('endDate');
    if(end){
      return moment(this.data.get('endDate'), "DD/MM/YYYY");
    }
    return moment();
  }
}
