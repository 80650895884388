import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path');
  }

  handleClick(){
    window.setTimeout(
      this.triggerHandlerAfterTimeout.bind(this),
      200
    );
  }

  triggerHandlerAfterTimeout(){
    window.KTApp.blockPage();
    window.Ajax.post(this.path, this.ajaxOptions)
  }

  handleSuccess(responseText){
    this.targetElement.innerHTML = responseText;
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }

  handleDone(){
    window.KTApp.unblockPage();
  }

  get targetElement(){
    if(this._targetElement){
      return this._targetElement;
    }

    this._targetElement = document.querySelector('#EventDetail .modal-dialog');
    return this._targetElement;
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
      ]
    };
  }
}
