import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns() {
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.created_at}</span>`;
        }
      },
      {
        field: 'count',
        title: 'Jumlah user / notifikasi',
        sortable: false,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.count}</span>`;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function (data) {
          return `
            <a
              class="btn btn-sm btn-clean btn-icon"
              href="${data.show_path}"
              title="Tampilkan"
            >
              <i class="fa fa-eye text-primary"></i>
            </a>
          `;
        },
      }
    ]
  }
}
