import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.password = this.element.querySelector('input[name="user[password]"],input[name="password"]');
    this.passwordHint = this.password.nextElementSibling;
    this.passwordConfirmation = this.element.querySelector('input[name="user[password_confirmation]"],input[name="password_confirmation"]');
    this.passwordConfirmationHint = this.passwordConfirmation.nextElementSibling;
    this.form = this.element.closest('form');
    this.submit = this.form.querySelector('button[type="submit"]');

    this.regValid = false;
  }
  connect(){
    this.password.addEventListener('input', this.handleChangePassword.bind(this));
    this.passwordConfirmation.addEventListener('input', this.handleChangePasswordConfirmation.bind(this));
    this.submit.setAttribute('disabled', 'disabled');
  }

  handleChangePassword(){
    this.validateRegexp();
    this.validateEquivalent();
  }

  handleChangePasswordConfirmation(){
    this.validateRegexp();
    this.validateEquivalent();
  }

  validateRegexp(){
    if(!this.regexp.test(this.password.value)){
      this.passwordHint.innerHTML = `
        <span style="color:red !important;font-weight:bolder;">*</span> Password harus terdiri dari:<br/>
        &nbsp;&nbsp;(1) Minimal 8 karakter<br/>
        &nbsp;&nbsp;(2) Minimal terdiri dari 1 angka<br/>
        &nbsp;&nbsp;(3) Minimal terdiri dari 1 huruf kecil<br/>
        &nbsp;&nbsp;(4) Minimal terdiri dari 1 huruf besar<br/>
        <!--&nbsp;&nbsp;(5) Minimal terdiri dari 1 simbol<br/>-->
      `.trim();
      this.submit.setAttribute('disabled', 'disabled');
      this.regValid = false;
      return;
    }
    this.passwordHint.innerHTML = '';
    this.regValid = true;
    // this.submit.removeAttribute('disabled');
  }

  validateEquivalent(){
    if(this.password.value !== this.passwordConfirmation.value){
      this.passwordConfirmationHint.innerHTML = `
        <span style="color:red !important;font-weight:bolder;">*</span> Password harus sama.
      `.trim();
      this.submit.setAttribute('disabled', 'disabled');
      return;
    }
    this.passwordConfirmationHint.innerHTML = '';
    if(this.regValid){
      this.submit.removeAttribute('disabled');
    }
  }

  get regexp(){
    // return new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  }
}

