import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.entries = [
      /^\/users\/orientation_90_days\/?/,
      /^\/users\/careers\/?/,
      /^\/users\/portfolios\/?/,
    ];
  }

  connect(){
    this.initScrollToTop();
    this.scrollFocusOnMobile();
  }

  scrollFocusOnMobile(){
    if(window.Util.isDesktop(768)){
      return;
    }

    this.entries.filter((x) => {
      if(window.location.pathname.match(x)){
        this.jsContainer.scrollIntoView();
        return;
      }
    })
  }

  initScrollToTop(){
    window.KTLayoutScrolltop.init('kt_scrolltop');
  }

  get jsContainer(){
    return document.querySelector('.js-container');
  }
}
