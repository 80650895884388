import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.id = this.data.get('id');
  }

  connect(){
    this.registerRoutes();
  }

  handleClick(e){
    window.KTApp.blockPage();
    // window.Ajax.post(this.path, this.ajaxOptions);
    window.location = this.path;
  }


  handleSuccess(responseText){
    this.parseAndRender(responseText);
    this.adjustPath();
  }

  adjustPath(){
    const path = window.location.pathname;
    const token = path.split('users/');
    let newPath = `${token[0]}users/${this.id}`;

    window.history.pushState({},'',newPath);
  }

  parseAndRender(responseText){
    const parsedResult = JSON.parse(responseText);
    const keys = Object.keys(parsedResult);
    for(let i=0; i<keys.length; i++){
      const selector = keys[i];
      const content = parsedResult[keys[i]];

      this.render(selector, content);
    }
  }

  render(selector, content){
    const element = document.querySelector(selector);
    element.innerHTML = content;
  }

  handleFail(responseText){
    console.log('[ERROR]', responseText);
  }

  handleDone(){
    window.Routes.actived = this.id;
    window.KTApp.unblockPage();
  }

  registerRoutes(){
    if(window.Routes){
      window.Routes.routes[this.id] = this.path;
      return;
    }
    window.Routes = {
      routes: {},
      actived: null
    }

    window.Routes.routes[this.id] = this.path;
  }

  get path(){
    // return `/api/users/${this.id}`;
    return `/users/${this.id}`;
  }

  get ajaxOptions(){
    return {
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this)
    }
  }
}
