import { Controller } from 'stimulus';

export default class extends Controller {
  handleChange(e){
    const prevElement = this.element.previousElementSibling;
    if(this.element.checked){
      prevElement.removeAttribute('disabled');
    } else {
      prevElement.setAttribute('disabled', 'disabled');
    }
  }
}
