import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path');
  }

  handleClick(){
    window.KTApp.block(this.skillDetailBodyElement);
    window.Ajax.post(this.path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    window.KTApp.unblock(this.skillDetailBodyElement);
    this.skillDetailBodyElement.innerHTML = responseText;
  }
  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }
  handleDone(){
    window.KTApp.unblock(this.skillDetailBodyElement);
  }

  get skillDetailBodyElement(){
    if(this._skillDetailBodyElement){
      return this._skillDetailBodyElement;
    }

    this._skillDetailBodyElement = document.querySelector('.js-skill-detail-body');
    return this._skillDetailBodyElement
  }

  get ajaxOptions(){
    return {
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this)
    }
  }
}
