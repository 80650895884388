import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path');
  }

  connect(){
    $(this.element).on('select2:select', this.handleChange.bind(this));
  }
  handleChange(e){
    const path = this.path.replace(/\-1/, this.element.value);
    window.KTApp.blockPage();
    window.Ajax.post(path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    this.portfolioInputContainerElement.innerHTML = responseText;
  }
  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }
  handleDone(){
    window.KTApp.unblockPage();
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
      ]
    };
  }

  get portfolioInputContainerElement(){
    if(this._portfolioInputContainerElement){
      return this._portfolioInputContainerElement;
    }

    this._portfolioInputContainerElement = document.querySelector('.js-portfolio-inputs-container-all');
    return this._portfolioInputContainerElement;
  }
}
