import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
  }

  connect(){
    window.setTimeout(() => {
      window.Util.removeElement(this.element);
    }, 2000);
  }
}
