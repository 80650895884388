import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path');
    this.listenerElements = Array.prototype.slice.call(
      document.querySelectorAll('.js-career-name')
    );
  }

  connect(){
    this.listenerElements.forEach((x) => {
      x.addEventListener('click', this.handleClick.bind(this));
    });
  }
  handleClick(e){
    const userCareerId = e.srcElement.dataset.userCareerId;
    const path = this.path.replace(/\-1/, userCareerId);

    window.KTApp.blockPage();
    window.Ajax.post(path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    this.element.innerHTML = responseText;
  }
  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }
  handleDone(){
    window.KTApp.unblockPage();
  }

  get ajaxOptions(){
    return {
      onDone: this.handleDone.bind(this),
      onFail: this.handleFail.bind(this),
      onSuccess: this.handleSuccess.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        }
      ]
    };
  }
}
