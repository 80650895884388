import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    if(this.element.classList.contains('js-initialized')){
      return;
    }
    this.element.classList.add('js-initialized');
    this.datatable = $(this.element).DataTable(this.options);
  }

  get options(){
    return {
      // retrieve: true,
      paging: false,
      pageLength: 25,
      // responsive: false,
      scrollY: '60vh',
      scrollX: true,
      scrollCollapse: true,
      // bInfo : false,
      ordering: false,
      fixedColumns: {
        left: 1
      }
    };
  }
}
