import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.bindedHandleBeforeVisit = this.handleBeforeVisit.bind(this);
  }

  connect(){
    window.addEventListener('turbolinks:before-visit', this.bindedHandleBeforeVisit);
  }

  disconnect(){
    window.removeEventListener('turbolinks:before-visit', this.bindedHandleBeforeVisit);
  }

  handleBeforeVisit(e){
    if(window.confirm(this.data.get('message'))){
      return;
    }

    e.preventDefault();
    this.loader.classList.remove('d-block');
  }

  get loader(){
    if(this._loader){
      return this._loader;
    }

    this._loader = document.querySelector('.my-loader-container');
    return this._loader;
  }
}
