import { Controller } from 'stimulus';
import Glide from '@glidejs/glide';

export default class extends Controller {
  initialize(){
    this.perView = this.data.get('perView');
  }

  connect(){
    if(this.data.get('lazyInitialization') === '1'){
      this.bindInitEvent();
      return;
    }

    this.glide = new Glide(this.element, this.options);
    this.glide.mount();
  }

  bindInitEvent(){
    this.element.addEventListener('glide:initializer', this.handleInitEvent.bind(this));
  }

  handleInitEvent(){
    if(this.element.classList.contains('lazyGlideInitialized')){
      return;
    }

    this.glide = new Glide(this.element, this.options);
    this.glide.mount();
    this.element.classList.add('lazyGlideInitialized');
  }

  get options(){
    const result = {
      type: 'carousel',
      startAt: 0,
      perView: 5,
    }

    if(this.data.get('lazyInitialization') === '1'){
      result['perView'] = 1;
    } else {
      result['perView'] = 2;
      result['breakpoints'] = {
        565: {
          perView: 1
        },
        767: {
          perView: 2
        },
        994: {
          perView: 1
        },
        1399: {
          perView: 2
        },
        1750: {
          perView: 3
        },
        2342: {
          perView: 4
        },
      }
    }

    if(this.perView){
      result['perView'] = 1;
      result['breakpoints'] = {565:{perView:1}}
    }

    return result;
  }
}
