import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns() {
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'user',
        title: 'User',
        sortable: false,
        template: function (data) {
          return `<span class="font-weight-bolder">${data.user}</span>`;
        }
      },
      {
        field: 'status',
        title: 'Status',
        template: function (data) {
          return `<span class="font-weight-bolder">${data.status}</span>`;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function (data) {
          // if(data.original_status === 'succeed'){
            // return '';
          // }
          return `
            <form action="${data.resend_path}" method="post">
              <input type="hidden" name="authenticity_token" value="${window.Util.getCsrfToken()}">
              <button
                class="btn btn-sm btn-clean btn-icon"
                href=""
                title="Kirim Ulang"
                data-controller="tooltip"
              >
                <i class="flaticon2-send-1 text-primary"></i>
              </button>
            </form>
          `;
        },
      }
    ]
  }
}
