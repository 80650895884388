import DatatablesController from '../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns() {
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function (data) {
          return `<span class="">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        template: function (data) {
          return `<span class="">${data.name}</span>`;
        }
      },
      {
        field: 'date',
        title: 'Tanggal Mulai',
        template: function (data) {
          return `<span class="">${data.date}</span>`;
        }
      },
      {
        field: 'ended_at',
        title: 'Tanggal Berakhir',
        template: function (data) {
          return `<span class="">${data.ended_at}</span>`;
        }
      },
      {
        field: 'shown',
        title: 'Tampil?',
        template: function (data) {
          let checkedHtml = '';
          if(data.shown){
            checkedHtml = 'checked="checked"';
          }

          return `
            <div class="text-center">
              <span class="switch">
                <label>
                  <input
                    type="checkbox"
                    ${checkedHtml}
                    name="select"
                    data-controller="admin--events--update-shown-on-change"
                    data-action="change->admin--events--update-shown-on-change#handleChange"
                    data-admin--events--update-shown-on-change-path="${data.update_shown_path}"
                  >
                  <span></span>
                </label>
              </span>
            </div>
          `;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function (data) {
          return `
            <a
              class="btn btn-sm btn-clean btn-icon"
              href="javascript:void(0);"
              title="Tampilkan"
              data-toggle="modal"
              data-target="#ShowEvent"
              data-controller="admin--events--show"
              data-action="click->admin--events--show#handleClick"
              data-admin--events--show-path="${data.show_path}"
            >
              <i class="fa fa-pencil-alt"></i>
            </a>
            <a
              href="${data.show_path}"
              data-method="delete"
              data-confirm="Apakah anda yakin ingin menghapus data ini?"
              class="btn btn-sm btn-clean btn-icon btn-hover-light-danger"
              title="Delete"
            >
              <i class="la la-trash"></i>
            </a>
            <a
              href="${data.invite_calendar_path}"
              data-method="post"
              data-confirm="Apakah anda yakin ingin lanjut?"
              class="btn btn-sm btn-clean btn-icon btn-hover-light-success"
              data-title="Invite CTMs to Google Calendar Event / Apply Update to Google Calendar Event"
              data-content="Mengundang seluruh CTM ke kegiatan event milik akun Google terdaftar / Sinkronisasi perubahan data event ke Google Calendar Event"
              data-controller="popover"
              data-popover-trigger="hover"
            >
              <i class="fab la-google-plus-g"></i>
            </a>
          `;
        },
      }
    ]
  }
}
